<template>
  <section class="call-actions" :class="mode">
    <v-row
        class="call-actions__wrapper container-wrapper"
    >
      <v-spacer></v-spacer>
      <v-col
          cols="12"
          sm="7"
          md="5"
          class="call-actions__text text-center"
      >
        <h3 class="call-actions__title text-center">
          {{ title }}
        </h3>
      </v-col>
      <v-col
          class="call-actions__button d-flex justify-center justify-sm-start"
          cols="12"
          sm="5"
          md="3"
      >
        <button
            class="green-btn"
            @click="$router.push(link)"
        >
          {{ btnText }}
        </button>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div class="call-actions__overlay"></div>
  </section>
</template>

<script>
export default {
  name: "CallToActionsSmall",
  props: {
    mode: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      required: true
    },
    link: {
      type: String
    },
  }
}
</script>


<style lang="scss" scoped>
.call-actions {
  position: relative;
  background: url('../../../assets/img/development/bg-small-block.webp') no-repeat center;
  background-size: cover;

  &.dark {
    background: url('../../../assets/img/development/bg-small-block-dark.webp') no-repeat center;
    background-size: cover;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    margin: 0 auto;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  &__text,
  &__text_wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  &__title {
    width: 100%;
    color: $light-text;
    line-height: 1.2;
    font-weight: bold;
    margin-right: 50px;
  }
}

@media (max-width: $mobile-large) {
  .call-actions {
    &__text,
    &__text_wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &__title {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}

@media (max-width: $pad-small) {
  .call-actions {
    background: url('../../../assets/img/development/bg-small-mobile.webp') no-repeat center;
    background-size: cover;
  }
}

@media (max-width: $desk-small) {
  .call-actions {
    &.dark {
      background: url('../../../assets/img/development/bg-small-dark-mobile.webp') no-repeat center;
      background-size: cover;
    }
  }
}

@media (max-width: $max-desk-small) {
  .call-actions {
    &__title {
      font-size: 32px;
    }
  }
}
</style>
