<template>
  <v-container id="aso-section" class="aso-section">
    <v-row>
      <v-col class="aso-section__header">
        <h2 class="aso-section__title">Professional App Store Optimization (ASO)</h2>
        <div class="aso-section__subtitle">Improve your app visibility within the app stores and increase conversion rates.</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="aso-section__image d-none d-lg-block"
      >
        <img src="@/assets/img/optimization/aso-mokup.webp" alt="">
      </v-col>
      <v-col
        cols="12"
        lg=""
      >
        <div class="aso-section__content">
          <div class="aso-section__item">
            <div class="aso-section__item_subtitle gradient-text-3">Stay competitive</div>
            <h3>Boost app visibility and generate revenue</h3>
            <div>Grow app audience, maximize profits, and leave your competitors behind.</div>
          </div>
          <div class="aso-section__item">
            <div class="aso-section__item_subtitle gradient-text-3">Google Play & App Store</div>
            <h3>Maximize engagements and improve rankings</h3>
            <div>Get noticeable in Google Play & App Store and drive app downloads.</div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AsoSection"
}
</script>

<style lang="scss" scoped>
.aso-section {
  padding-top: 60px;
  padding-bottom: 70px;

  &__header {
    text-align: center;
    padding-bottom: 30px;
    color: $color-primary;
  }
  &__title {
    margin-bottom: 12px;
  }
  &__image {
    text-align: center;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    color: $color-primary;
  }
  &__item {
    padding: 0 15px;
    h3 {
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 14px;
    }
  }
  &__item_subtitle {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
  }

  @media (max-width: $mobile-large) {
    &__header {
      text-align: center;
      padding-bottom: 10px;
    }
    &__content {
      flex-wrap: wrap;
      text-align: center;
    }
    &__item:first-child {
      margin-bottom: 22px;
    }
  }

  @media (min-width: $pad-medium) {
    padding-top: 140px;
    padding-bottom: 160px;

    &__header {
      padding-bottom: 60px;
    }
    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      max-width: 480px;
      margin-left: 10px;
    }
  }
  @media (min-width: $desk-large) {
    &__content {
      max-width: 480px;
      margin-left: 0;
    }
    &__item {
      padding: 0;
    }
  }
}
</style>
