<template>
  <v-container class="tiger-section">
    <v-row>
      <v-col
        class="tiger-section__img d-none d-md-block"

      >
        <img src="@/assets/img/optimization/tiger.webp" alt="">
      </v-col>
      <v-col
        class="tiger-section__content"

      >
        <div>
          <h3 class="tiger-section__title">Boosting business visibility is simple</h3>
          <div class="tiger-section__text">
            We provide reliable solutions for Search Engine Optimization (SEO), App Store Optimization (ASO), Online Reputation Management (ORM), and even more to help your business thrive.
          </div>
          <div class="tiger-section__actions">
            <v-btn
                rounded
                class="tiger-section__btn1 px-8"
                color="light-green"
                dark
                x-large
                depressed
                @click="$router.push('/contact')"
            >
              Work with us
            </v-btn>
            <a href="https://olehdankevych.com/portfolio/" target="_blank" style="text-decoration:none;">
                <v-btn
                    rounded
                    class="tiger-section__btn2 px-5"
                    x-large
                    text
                    color="#707379"
                >
                    View Portfolio
                </v-btn>
            </a>


          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: "TigerSection"
}
</script>

<style lang="scss" scoped>
.tiger-section {
  color: $color-primary;
  padding-top: 60px;
  padding-bottom: 60px;

  &__img {
    text-align: center;

    img {
      max-width: 100%;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    & > div {
      max-width: 590px;
    }
  }
  &__title {
    margin-bottom: 12px;
    line-height: 1.1;
    font-weight: 500;
  }
  &__text {
    line-height: 24px;
  }
  &__actions {
    padding-top: 30px;

    button {
      text-transform: inherit !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  &__btn1 {
    margin: 16px 8px;
  }
  &__btn1,
  &__btn2 {
    font-size: 14px;
    height: 44px !important;
  }

  @media (min-width: $pad-small) {
    padding-top: 90px;
    padding-bottom: 100px;

    &__content {
      text-align: left;
    }
    &__actions {
      padding-top: 50px;
    }
    &__btn1 {
      margin: 0 12px 0 0;
    }
    &__btn1,
    &__btn2 {
      padding-left: 12px !important;
      padding-right: 12px !important;
      font-size: 14px;
      height: 44px !important;
    }
  }
  @media (min-width: $pad-medium) {
    &__btn1,
    &__btn2 {
      padding-left: 20px !important;
      padding-right: 20px !important;
      font-size: 16px;
      height: 52px !important;
    }
  }
  @media (min-width: $desk-medium) {
    padding-top: 120px;
    padding-bottom: 140px;
  }
}
</style>
