<template>
  <div class="optimization-page">
    <AnchorMenu :links="anchorLinks" />
    <SectionDevelopment
      title="Successful SEO Strategy"
      subtitle="We deliver effective SEO solutions to help businesses grow and increase their revenue."
      :image="imgDevelopment"
      :first-block="developmentText.firstBlock"
      :second-block="developmentText.secondBlock"
      :image-is-big="true"
    />
    <a href="https://olehdankevych.com/portfolio/" target="_blank" style="text-decoration:none;">
      <CallToActionsSmall
        mode="light"
        title="ARI.company portfolio"
        btn-text="View"
      />
    </a>


    <AsoSection />
    <OptimizationReviews />
    <OrmSection />


    <a href="https://olehdankevych.com/portfolio/" target="_blank" style="text-decoration:none;">
      <CallToActionsSmall
        mode="light"
        title="ARI.company portfolio"
        btn-text="View"
      />
    </a>


    <WeHelpSection />
    <SectionFaq :items="faqItems" />
    <TigerSection />
  </div>
</template>

<script>
import AnchorMenu from "@/components/particles/AnchorMenu"
import SectionDevelopment from "../Development/Sections/Development/SectionDevelopment"
import CallToActions from "@/components/particles/CallToActions/CallToActions"
import SectionFaq from "../Development/Sections/Faq/SectionFaq"
import TigerSection from "./Sections/TigerSection"
import AsoSection from "./Sections/AsoSection"
import OptimizationReviews from "./Sections/OptimizationReviews"
import OrmSection from "./Sections/OrmSection"
import WeHelpSection from "./Sections/WeHelpSection"
import ContentCarousel from "../../components/MainCarousel/ContentCarousel";
import ContentCarouselItem from "../../components/MainCarousel/ContentCarouselItem";
import CallToActionsSmall from "../../components/particles/CallToActions/CallToActionsSmall";

export default {
  name: "OptimizationPage",
  components: {
    CallToActionsSmall,
    ContentCarouselItem,
    ContentCarousel,
    WeHelpSection,
    OrmSection,
    OptimizationReviews,
    AsoSection,
    TigerSection,
    SectionFaq,
    CallToActions,
    SectionDevelopment,
    AnchorMenu
  },
  data () {
    return {
      items: [
        {
          image: require('@/assets/img/optimization/Pic_portfolio.webp'),
          title: 'SEO: Amazon Affiliate website - Cell Phones & Accessori',
          hours: '1920',
          location: 'USA, CA',
          platforms: [ 'mdi-google-play'],
          content: `
            <div class="mt-8"><b>Initial indicators of the project:</b></div>
            <ul>
               <li>Just an idea and finances</li>
            </ul>
            <div class="mt-4"><b>The result of 12 months of work:</b></div>
            <ul>
               <li>Project costs 1920 hours</li>
               <li>Invested: 25%</li>
               <li>Profit: 75%</li>
               <li>ROI: 300%</li>
            </ul>
          `
        }
      ],
      anchorLinks: [
        { id: 1, title: 'Search Engine Optimization (SEO)', link: '#section-development', isActive: true },
        { id: 2, title: 'App Store Optimization (ASO)', link: '#aso-section', isActive: false },
        { id: 3, title: 'Online Reputation Management (ORM)', link: '#orm-section', isActive: false },
      ],
      imgDevelopment: {
        src: require('@/assets/img/optimization/tablet.webp'),
        alt: 'IMac'
      },
      developmentText: {
        firstBlock: [
          { title: 'Earn the highest ROI', subtitle: 'Investments & profits', text: 'Achieve higher returns online by investing in the most profitable products.' },
          { title: 'Get a comprehensive business plan', subtitle: 'Technology & tools', text: 'Obtain a customized marketing strategy to help achieve your business growth.' },
        ],
        secondBlock: [
          { title: 'Improve your rankings', subtitle: 'SEO & ASO & ORM', text: 'Boost your website’s visibility in search engines, get organic traffic, and engage new visitors in a short time.' },
          { title: 'Stay ahead of your competition', subtitle: 'Competence & analysis', text: 'Get a proper analysis of your competitors and get a custom-tailored strategy to stay one step ahead.' },
        ]
      },
      faqItems: [
        {
          id: 1,
          title: "What is SEO, and what does it do?",
          text: "SEO stands for Search Engine Optimization, which aims at improving your website's presence in search results on different search engines, for instance, Google."
        },
        {
          id: 2,
          title: "Why do I need to invest in SEO? ",
          text: "SEO allows you to increase traffic to your website, make your business noticeable, attract more customers, and consequently grow revenue."
        },
        {
          id: 3,
          title: "How much does SEO cost?",
          text: "Each project is unique, and the price for search engine optimization depends on different factors. Let us know the details, and we'll let you know the final price for your project."
        },
        {
          id: 4,
          title: "Can you help me to rank above my competitors?",
          text: "Yes! We'll need some time to analyze your competitors and their approaches to SEO, and then we'll create an effective strategy to help your website perform stronger than your competitors."
        },
        {
          id: 5,
          title: "What is the best niche for SEO?",
          text: "There're many of them. In short, any business can leverage free traffic to their websites with the right SEO strategy."
        },
        {
          id: 6,
          title: "How to pick the right keywords to be targeted?",
          text: "While conducting keyword research for a new project, we take a lot of factors into account: we find the most competitive words for the industry, look at the competitors, analyze the results, and of course, discuss the potential list with our customers."
        },
        {
          id: 7,
          title: "What do I need to get started with SEO?",
          text: "Getting started is very simple, contact ARI.company, provide the details of your project, and we’ll do all the work for you."
        },
        {
          id: 8,
          title: "What is ASO, and what does it do?",
          text: "App Store Optimization (ASO) refers to improving your app visibility in the app stores (e.g., Google Play & App store) and increasing the conversing rates. When it comes to ASO, we provide deep analysis for our customers to help them make their app easily discoverable by the potential users and maximize the downloads."
        },
        {
          id: 9,
          title: "What is the difference between ASO and SEO?",
          text: "Both processes are aimed to improve conversion. Thus, they have different ranking factors. ASO is used for mobile apps, while SEO is used for websites."
        },
        {
          id: 10,
          title: "What is ORM?",
          text: "In simple words, online reputation is what others see when they search for you online. Online Reputation Management (ORM) is responsible for creating a positive reputation and shaping brand perception."
        },
      ]
    }
  }
}
</script>
