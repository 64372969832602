






























import Vue from 'vue'

export default Vue.extend({
  name: "WeHelpSection",
  data () {
    return {
      steps: [
        {
          image: {
            src: require('@/assets/img/optimization/1.webp'),
            alt: 'Step 1'
          },
          subtitle: 'marketing research',
          title: 'Get customized business research',
          text: 'Our team conducts a deep-dive analysis for your industry, brand, or product, focusing on market size, competitors, and recent trends.'
        },
        {
          image: {
            src: require('@/assets/img/optimization/2.webp'),
            alt: 'Step 2'
          },
          subtitle: 'Strategy planning',
          title: 'Receive a custom-made strategy',
          text: 'We provide a complete marketing strategy for transforming your business weaknesses into strengths, improving conversion rates, and increasing sales.'
        },
        {
          image: {
            src: require('@/assets/img/optimization/3.webp'),
            alt: 'Step 3'
          },
          subtitle: 'Beneficial results',
          title: 'Reap the benefits',
          text: 'Take advantage of the tailor-made strategy for your needs and reap the benefits to move your business forward.'
        },
      ]
    }
  }
})
