





































import Vue from 'vue'
import ContentCarouselItemContent from "@/components/MainCarousel/ContentCarouselItemContent.vue"

export default Vue.extend({
  name: "ContentCarouselItem",
  components: {ContentCarouselItemContent},
  props: {
    content: { required: true },
  },
  data () {
    return {
      isActive: false
    }
  }
})
