<template>
  <div class="carousel-content">
    <v-row no-gutters>
      <div>
        <h4
          class="carousel-content__title"
          :class="{'light': light}"
        >
          {{ title }}
        </h4>
        <div
            v-html="content"
            class="carousel-content__text"
            :class="{'light': light}"
        ></div>
      </div>
    </v-row>
    <v-row class="carousel-content__stats">
      <v-col
        class="carousel-content__stats_item"
        :class="{'light': light}"
      >
        <div class="carousel-content__stats_title">Hours</div>
        <div>{{ hours }}</div>
      </v-col>
      <v-col
        class="carousel-content__stats_item"
        :class="{'light': light}"
      >
        <div class="carousel-content__stats_title">Locations</div>
        <div>{{ location }}</div>
      </v-col>
      <v-col
        class="carousel-content__stats_item"
        :class="{'light': light}"
      >
        <div class="carousel-content__stats_title">Platforms</div>
        <div class="carousel-content__stats_platforms">
          <v-icon
            v-for="(platform, i) in platforms"
            :key="i"
            :color="iconColor"
          >
            {{ platform }}
          </v-icon>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ContentCarouselItemContent",
  props: {
    light: { type: Boolean, default: false, },
    title: { type: String, required: true },
    content: { type: String, required: true },
    hours: { type: String, required: true },
    location: { type: String, required: true },
    platforms: { type: Array, required: true },
  },

  computed: {
    iconColor: function () {
     return  this.light ? '#fff' : 'primary'
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-content {
  display: flex;
  flex-direction: column;
  padding: 24px !important;

  &__title {
    line-height: 1.3;
    margin-bottom: 12px;

    &.light {
      color: #fff;
      font-weight: 500;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 20px;

    &.light {
      color: #dedede;
    }
  }
  &__stats {
    color: $dark-text;
    max-height: 60px;
    margin-bottom: 8px;
  }
  &__stats_item {
    padding: 0 !important;
    text-align: center;

    &:not(:last-child) {
      border-right: 2px solid #EDEDED;
    }
    &.light {
      color: #dedede;
    }
  }
  &__stats_title {
    width: 100%;
    opacity: 0.44;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 8px;
  }
  &__stats_platforms {
    display: flex;
    justify-content: center;
  }

  @media (min-width: $mobile-large) {
    padding: 64px !important;

    &__text {
      font-size: 14px;
      line-height: 24px;
    }
    &__stats {
      max-height: 64px;
      margin-bottom: 0;
    }
    &__stats_title {
      margin-bottom: 18px;
    }
  }
}
</style>
