

















import  Vue from 'vue'
import { Slide } from 'vue-carousel-3d'
import MainCarousel from "@/components/MainCarousel/MainCarousel.vue";
import ContentCarouselItem from "@/components/MainCarousel/ContentCarouselItem.vue";

export default Vue.extend({
  name: "ContentCarousel",
  components: {
    Slide,
    ContentCarouselItem,
    MainCarousel
  },

  props: {
    items: { required: true },
    carouselOptions: { required: false },
  },

  data () {
    return {
      screenSizes: {
        small: {
          width: 448,
          height: 700,
          spacing: 400
        },
        medium: {
          width: 950,
          height: 640,
          spacing: 450
        },
        large: {
          width: 1100,
          height: 700,
          spacing: 630
        }
      },
    }
  }
})
