import { render, staticRenderFns } from "./ContentCarouselItemContent.vue?vue&type=template&id=617ba1f4&scoped=true&"
import script from "./ContentCarouselItemContent.vue?vue&type=script&lang=js&"
export * from "./ContentCarouselItemContent.vue?vue&type=script&lang=js&"
import style0 from "./ContentCarouselItemContent.vue?vue&type=style&index=0&id=617ba1f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "617ba1f4",
  null
  
)

export default component.exports