<template>
  <v-container id="orm-section" class="orm-section">
    <v-row>
      <v-col class="orm-section__header">
        <h2 class="orm-section__title">Effective Online Reputation Management (ORM)</h2>
        <div class="orm-section__subtitle">We build a solid online presence for individuals and brands.</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="">
        <div class="orm-section__content">
          <div class="orm-section__item">
            <div class="orm-section__item_subtitle gradient-text-3">Positive reviews</div>
            <h3>Maintain a great online reputation</h3>
            <div>Build an impeccable reputation in search engines and marketplaces to improve your brand's visibility, build trust, and increase revenue.</div>
          </div>
          <div class="orm-section__item">
            <div class="orm-section__item_subtitle gradient-text-3">Brand awareness</div>
            <h3>Rank higher in online searches</h3>
            <div>Obtain a positive online reputation for your brand or product and improve your ranking in search engines.</div>
          </div>
        </div>
      </v-col>
      <v-col class="orm-section__image d-none d-lg-block">
        <img src="@/assets/img/optimization/orm-image.webp" alt="">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OrmSection"
}
</script>

<style lang="scss" scoped>
.orm-section {
  padding-top: 60px;
  padding-bottom: 70px;

  &__header {
    text-align: center;
    padding-bottom: 30px;
    color: $color-primary;
  }
  &__title {
    margin-bottom: 12px;
  }
  &__image {
    text-align: center;

    img {
      max-width: 100%;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    color: $color-primary;
  }
  &__item {
    padding: 0 15px;
    h3 {
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 14px;
    }
    &:first-child {
      margin-bottom: 50px;
    }
  }
  &__item_subtitle {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
  }

  @media (max-width: $mobile-large) {
    &__header {
      text-align: center;
      padding-bottom: 10px;
    }
    &__content {
      flex-wrap: wrap;
      text-align: center;
    }
    &__item:first-child {
      margin-bottom: 22px;
    }
  }

  @media (min-width: $pad-medium) {
    padding-top: 140px;
    padding-bottom: 160px;

    &__header {
      padding-bottom: 60px;
    }
    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-top: -30px;
    }

    &:first-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: $desk-medium) {
  }

  @media (min-width: $desk-large) {
    &__content {
      margin-left: 90px;
      margin-right: 120px;
    }
    &__item {
      padding: 0;
    }
  }
}
</style>
